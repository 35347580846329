import Header from "./components/Header";
import Translator from "./components/Translator";
import Footer from "./components/Footer";

import "./App.css";

function App() {
    return (
        <div id="appContainer">
            <div id="alert"><h3>This device's display width is not supported. Site may not function as intended.</h3></div>
            <Header/>
            <Translator/>
            <Footer/>
        </div>
    );
}

export default App;
