import React from "react";
import fontList from "./fontList.json";
import './Header.css';

const logo = "./FantasyTranslatorLogo.png";

export default function Header(){
    const [hiddenTextFont, setHiddenTextFont] = React.useState("");

    setTimeout(() => {
        const hiddenTextElement = document.getElementById("hiddenText");
        if(hiddenTextElement){
            hiddenTextElement.addEventListener("mouseover", () => {randomFontSelector()})
        }
    }, 500)
    

    return(
        <div id="headerContainer">
            <div id="logoContainer">
                <a href="https://www.spectrecreations.com"><img id="logo" src={logo} alt="Fantasy Translator"/></a>
                <div id="logoTextContainer">
                    <h1 id="logoText">Fantasy Translator</h1>
                    <h1 id="hiddenText" style={{fontFamily: hiddenTextFont}}>fantasy translator</h1>
                </div>
            </div>
            <nav>
                <a href="https://www.reddit.com/r/FantasyTranslator/"><img src="./icons/Reddit.png" alt="Reddit"/><span>Visit the Subreddit</span></a>
            </nav>
        </div>
    )

    function randomFontSelector(){
        let newFont = hiddenTextFont;
        // Loop until we get a new font, so we don't get the same font over and over again
        while(newFont === hiddenTextFont){
            // length - 1 because we don't want to return "Common" as an option, + 1 to skip it in the index
            const selector = Math.floor(Math.random() * (fontList.length - 1) + 1);
            newFont = fontList[selector].fontName;
        }
        setHiddenTextFont(newFont);
    }
}