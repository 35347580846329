import * as React from "react";

import ImageCanvas from "./ImageCanvas";
import imageList from "./imageList.json"; // JSON of all available images, needs to be updated when new images get added
import fontList from "./fontList.json"; // JSON of all available fonts, needs to be updated when new fonts get added

import "./Translator.css"

export interface ImageListItem {
    title: string,
    small: ImageSizeItem,
    medium: ImageSizeItem,
    large: ImageSizeItem,
}

export interface ImageSizeItem {
    src: string,
    padding: number[], // top, right, bottom, left
    displayedSrc?: string,
}

export interface FontListItem {
    title: string,
    fontName: string,
    scale: number
}

export type ImageSize = "small" | "medium" | "large";

export type SettingType = "textSize" | "textColor" | "background" | "canvasScale" | "fileFormat" | "none";

const CANVAS_HEIGHT = 1100;
const CANVAS_WIDTH = 850;

const MAX_TEXT_SIZE = 120;
const MIN_TEXT_SIZE = 40;

export default function Translator(){

    const [text, setText] = React.useState("");
    const [selectedImage, setSelectedImage] = React.useState<ImageListItem>(imageList[0]);
    const [imageVariant, setImageVariant] = React.useState<ImageSize>("large");
    const [imageHeight, setImageHeight] = React.useState(CANVAS_HEIGHT);
    const [selectedFont, setSelectedFont] = React.useState<FontListItem>(fontList[3]);
    const [lastSelectedFonts, setLastSelectedFonts] = React.useState<FontListItem[]>([fontList[2], fontList[0]]);
    const [textSize, setTextSize] = React.useState(60);
    const [textColor, setTextColor] = React.useState("#232225");

    const [displayedSettings, setDisplayedSettings] = React.useState<SettingType>("none");

    const [downloadURL, setDownloadURL] = React.useState("");

    // // Used for canvas refreshing hacks
    // const [canvasScale, setCanvasScale] = React.useState(1);

    React.useEffect(() => {
        const canvas = document.getElementById("imageCanvas") as HTMLCanvasElement;
        const url = canvas.toDataURL("image/jpeg");
        setDownloadURL(url);
    }, [text, selectedImage, selectedFont, textSize, textColor, setDownloadURL])

    return(
        <div id="translatorContainer">
            <div className="translatorSection">
                <div id="textContainer">
                    <div id="fontContainer">
                        <div id="popularFonts">
                            <button className="selectedFont">
                                {selectedFont.title.toUpperCase()}
                            </button>
                            {lastSelectedFonts.map((font) => {
                                return <button key={`font_${font.title}`} onClick={() => handleFontChange(fontList.indexOf(font))}>{font.title.toUpperCase()}</button>
                            })}
                        </div>
                        <select value="none" onChange={e => handleFontChange(parseInt(e.target.value))}>
                            <option value="none" disabled hidden>MORE LANGUAGES</option>
                            {fontList.map((font, index) => {
                                return <option key={`font_${font.title}`} value={index}>{font.title.toUpperCase()}</option>
                            })}
                        </select>
                    </div>
                    <textarea
                        id="plainTextInput"
                        value={text}
                        placeholder="Type to translate..."
                        onChange={e => setText(e.target.value)}
                    />
                </div>
                <div id="settingsContainer">
                    <h3>OUTPUT SETTINGS</h3>
                    <div id="outputButtonContainer">
                        <div id="settingButtonContainer">
                            <button 
                                className={displayedSettings === "textSize" ? "selectedSetting" : undefined}
                                onClick={() => handleOutputButtonClick("textSize")}>
                                <img alt="textSize-icon" src="./icons/TextSize.png"/>
                                Text Size
                            </button>
                            <button
                                className={displayedSettings === "textColor" ? "selectedSetting" : undefined}
                                onClick={() => handleOutputButtonClick("textColor")}>
                                <img alt="textColor-icon" src="./icons/TextColor.png"/>
                                Text Color
                            </button>
                            <button
                                className={displayedSettings === "background" ? "selectedSetting" : undefined}
                                onClick={() => handleOutputButtonClick("background")}>
                                <img alt="background-icon" src="./icons/Background.png"/>
                                Background
                            </button>
                            <button
                                className={displayedSettings === "canvasScale" ? "selectedSetting" : undefined}
                                onClick={() => handleOutputButtonClick("canvasScale")}>
                                <img alt="pageSize-icon" src="./icons/PageSize.png"/>
                                Page Size
                            </button>
                            {/* <button
                                className={displayedSettings === "fileFormat" ? "selectedSetting" : undefined}
                                onClick={() => handleOutputButtonClick("fileFormat")}>
                                <img alt="fileFormat-icon" src="./icons/FileFormat.png"/>
                                File Format
                            </button> */}
                        </div>
                        <a 
                            id="downloadButton"
                            className={!text.trim() ? "disabled" : undefined}
                            download={text ? "fantasy_translation.jpg" : null}
                            href={text ? downloadURL : undefined}
                        >
                            <img alt="download-icon" src="./icons/Download.png"/>
                            Save Image
                        </a>
                    </div>
                    {renderDisplayedSettings()}
                </div>
                <div id="adSpaceDesktop">
                    <a href="https://www.kickstarter.com/projects/spectrecreations/chronicle-of-runes-5e?ref=19l4ez"><img src="/images/Project_Image-FTsite-shadow.jpg" alt="Caliya's Chronicle of Runes"/></a>
                    <br/>
                    <a href="https://www.kickstarter.com/projects/spectrecreations/chronicle-of-runes-5e?ref=19l4ez" style={{textDecoration: "none"}}><span>Developed for <b>Caliya's Chronicle of Runes</b>:</span></a>
                    <a href="https://www.kickstarter.com/projects/spectrecreations/chronicle-of-runes-5e?ref=19l4ez" style={{textDecoration: "none"}}><span><i>A Runic Supplement for D&D 5e</i></span></a>
                    <br/>
                    <span><a href="https://www.kickstarter.com/projects/spectrecreations/chronicle-of-runes-5e?ref=19l4ez">Check out the project LIVE on Kickstarter now!</a></span>
                </div>
            </div>

            <div className="translatorSection">
                <ImageCanvas
                    text={text}
                    textColor={textColor}
                    textFont={selectedFont.fontName}
                    fontScale={selectedFont.scale}
                    textSize={textSize}
                    padding={selectedImage[imageVariant].padding}
                    imageSrc={selectedImage[imageVariant].src}
                    imageWidth={CANVAS_WIDTH}
                    imageHeight={imageHeight}
                    displayedSrc={selectedImage[imageVariant].displayedSrc}
                />
                <div id="fontPreloader">
                    {fontList.map(font => {
                        // We have to pre-render these fonts, otherwise we risk font's not being loaded at the time ImageCanvas JS is executed
                        return(
                            <div key={`font_${font.fontName}`}style={{fontFamily: font.fontName}}>.</div>
                        )
                    })}
                </div>
                <div id="imagePreloader">
                    {imageList.map(image => {
                        return <div key={`preLoad_${image.title}`}>
                            <img key={`large_${image.title}`} alt={`preLoad of ${image.title}`} src={image.large.src}/>
                            <img key={`medium_${image.title}`} alt={`preLoad of ${image.title}`} src={image.medium.src}/>
                            <img key={`small_${image.title}`} alt={`preLoad of ${image.title}`} src={image.small.src}/>
                            {/* If we have a displayedSrc, preload that as well */}
                            {image.large.displayedSrc && <img key={`largeDisplayed_${image.title}`} alt={`preLoad of ${image.title}`} src={image.large.displayedSrc}/>}
                            {image.medium.displayedSrc && <img key={`mediumDisplayed_${image.title}`} alt={`preLoad of ${image.title}`} src={image.medium.displayedSrc}/>}
                            {image.small.displayedSrc && <img key={`smallDisplayed_${image.title}`} alt={`preLoad of ${image.title}`} src={image.small.displayedSrc}/>}
                        </div>
                    })}
                </div>
            </div>
            <div id="adSpaceMobile">
                <a href="https://www.kickstarter.com/projects/spectrecreations/chronicle-of-runes-5e?ref=19l4ez"><img src="/images/Project_Image-FTsite-shadow.jpg" alt="Caliya's Chronicle of Runes"/></a>
                <br/>
                <a href="https://www.kickstarter.com/projects/spectrecreations/chronicle-of-runes-5e?ref=19l4ez" style={{textDecoration: "none"}}><span>Developed for <b>Caliya's Chronicle of Runes</b>:</span></a>
                <a href="https://www.kickstarter.com/projects/spectrecreations/chronicle-of-runes-5e?ref=19l4ez" style={{textDecoration: "none"}}><span><i>A Runic Supplement for D&D 5e</i></span></a>
                <br/>
                <span><a href="https://www.kickstarter.com/projects/spectrecreations/chronicle-of-runes-5e?ref=19l4ez">Check out the project LIVE on Kickstarter now!</a></span>
            </div>
        </div>
    )

    function renderDisplayedSettings(){
        const defaultColors = ["#292929", "#6E6E6E", "#DEDEDE", "#FFFFFF", "#D9C6B4", "#8F8276", "#375186", "#924545", "#C12F2F", 
            "#C12FA1", "#8E2FC1", "#312FC1", "#2FA9C1", "#2FC147", "#FFEC1A", "#DA8A3E"];
        let settingsControls;
        switch(displayedSettings){
            case "textSize":
                settingsControls = <input type="range" value={textSize} min={MIN_TEXT_SIZE} max={MAX_TEXT_SIZE} onChange={e => setTextSize(parseInt(e.target.value))}/>
                break;
            case "textColor":
                settingsControls = <div style={{display: "flex"}}>
                        <input type="color" value={textColor} onChange={e => setTextColor(e.target.value)}/>
                        <div style={{display: "flex", flexWrap: "wrap"}}>
                            {defaultColors.map(color => {
                                return <button 
                                    key={`colorButton-${color}`}
                                    className={`colorButton ${textColor === color && "selected"}`}
                                    onClick={() => setTextColor(color)}
                                    style={{backgroundColor: color}}
                                    />
                            })}
                        </div>
                    </div>
                break;
            case "background": 
                settingsControls = <div style={{display: "flex", flexWrap: "wrap"}}>
                    {imageList.map(image => {
                        return <div key={`backgroundButtonContainer-${image.title}`} className="backgroundButtonContainer">
                                <button
                                    key={`backgroundButton-${image.title}`}
                                    className={`backgroundButton ${selectedImage === image && "selected"}`}
                                    style={{backgroundImage: `url(${image.large.src})`}}
                                    onClick={() => setSelectedImage(image)}
                                />
                                <span className={selectedImage === image ? "selected" : undefined}>{image.title}</span>
                            </div>
                    })}
                    </div>
                break;
            case "canvasScale":
                settingsControls = <input type="range" value={getNumberFromVariant()} min="1" max="3" onChange={e => handleVariantChange(parseInt(e.target.value))}/>
                break;
            case "fileFormat":
                settingsControls = <h3>FileFormat</h3>
                break;
            default:
                break;
        }
        if(displayedSettings !== "none"){
            return (
                <div id="settingsControls">
                    {settingsControls}
                </div>
            )
        }
        else {
            return;
        }
    }

    function handleOutputButtonClick(newDisplayedSettings: SettingType){
        if(newDisplayedSettings !== displayedSettings){
            setDisplayedSettings(newDisplayedSettings);
        } else {
            setDisplayedSettings("none");
        }
    }

    function handleFontChange(newFontIndex: number){
        // Update our lastSelectedFonts to always show the last 2 selected fonts
        let lastUsedFont;
        if(newFontIndex === fontList.indexOf(selectedFont)){
            return;
        }
        if(newFontIndex !== fontList.indexOf(lastSelectedFonts[0])){
            lastUsedFont = lastSelectedFonts.shift();
        } else {
            lastUsedFont = lastSelectedFonts.pop();
        }
        if(lastUsedFont){
            setLastSelectedFonts([selectedFont, lastUsedFont])
        }
        lastSelectedFonts.unshift(selectedFont);
        
        setSelectedFont(fontList[newFontIndex]);
    }

    function getNumberFromVariant(){
        switch(imageVariant){
            case "small":
                return 1;
            case "medium":
                return 2;
            case "large":
                return 3;
        }
    }
    function handleVariantChange(newVariantNumber: number){
        switch (newVariantNumber){
            case 1:
                setImageVariant("small");
                setImageHeight(Math.floor(CANVAS_HEIGHT * .33))
                break;
            case 2:
                setImageVariant("medium");
                setImageHeight(Math.floor(CANVAS_HEIGHT * .66))
                break;
            case 3:
                setImageVariant("large");
                setImageHeight(Math.floor(CANVAS_HEIGHT))
                break;
        }
    }
}
