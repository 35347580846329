import * as React from "react";

import "./ImageCanvas.css";

interface IProps {
    text: string;
    textColor: string;
    textFont: string;
    fontScale: number;
    textSize: number; // in pixels, used for font size && line height
    padding: number[]; // top, right, bottom, left
    imageSrc: string;
    imageWidth: number;
    imageHeight: number;
    displayedSrc?: string;
}

export default function ImageCanvas(props: IProps){
    const [placeholderDisplay, setPlaceholderDisplay] = React.useState("block");
    const [canvasDisplay, setCanvasDisplay] = React.useState("none");
    const [prevProps, setPrevProps] = React.useState<IProps>();
    const [prevTextLines, setPrevTextLines] = React.useState<string[]>([]);
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const imageRef = React.useRef<HTMLImageElement>(null);
    const displayedCanvasRef = React.useRef<HTMLCanvasElement>(null);
    const displayedImageRef = React.useRef<HTMLImageElement>(null);

    React.useEffect(() => {
        const scaledTextSize = Math.floor(props.textSize * props.fontScale)
        const lineHeight = Math.floor(scaledTextSize * 1.5);
        const canvas = canvasRef.current;
        let textLines: string[] = prevTextLines;
        if(canvas){
            const ctx = canvas.getContext("2d");
            const image = imageRef.current;
            if(ctx && image){
                if(textLinesShouldUpdate()) textLines = getTextLines(props.text, ctx);
                ctx.drawImage(image, 0, 0, props.imageWidth, props.imageHeight);
                ctx.font = `${scaledTextSize}px ${props.textFont}`;
                ctx.fillStyle= props.textColor;
                for(let i = 0; i < textLines.length; i++){
                    const newLineHeight = props.padding[0] + i * lineHeight;
                    if(newLineHeight < props.imageHeight - (props.padding[2] + scaledTextSize)){
                        ctx.fillText(
                            textLines[i],
                            props.padding[3] + lineHeight / 10,
                            props.padding[0] + lineHeight / 2 + i * lineHeight
                        );
                    }
                }
            }
        }
        const displayedCanvas = displayedCanvasRef.current;
        if(displayedCanvas){
            const displayedCtx = displayedCanvas.getContext("2d");
            const displayedImage = displayedImageRef.current;
            if(displayedCtx && displayedImage){
                displayedCtx.drawImage(displayedImage, 0, 0, props.imageWidth, props.imageHeight);
                displayedCtx.font = `${scaledTextSize}px ${props.textFont}`;
                displayedCtx.fillStyle= props.textColor;
                for(let i = 0; i < textLines.length; i++){
                    const newLineHeight = props.padding[0] + i * lineHeight;
                    if(newLineHeight < props.imageHeight - (props.padding[2] + scaledTextSize)){
                        displayedCtx.fillText(
                            textLines[i],
                            props.padding[3] + lineHeight / 10,
                            props.padding[0] + lineHeight / 2 + i * lineHeight
                        );
                    }
                }
            }
        }
        // Show our image until our canvas is being written on
        if(props.text.length > 0){
            setPlaceholderDisplay("none");
            setCanvasDisplay("block");
        }

        function getTextLines(text: string, ctx: CanvasRenderingContext2D){
            const strings = text.split(" ");
            const words = [];
            // Find line breaks, and seperate them from the actual words
            for (const string of strings){
                if(string.includes("\n")){
                    let dirtyString = string;
                    do {
                        const lineBreakIndex = dirtyString.indexOf("\n");
                        words.push(dirtyString.slice(0, dirtyString.indexOf("\n")))
                        words.push("\n");
                        dirtyString = dirtyString.slice(lineBreakIndex + "\n".length);
                    } while(dirtyString.includes("\n"));
                    words.push(dirtyString);
                } else if(string.length){
                    words.push(string);
                }
            }
            const lines = [];
            let currentLine = "";
    
            for (const word of words) {
                if(word === "\n"){
                    lines.push(currentLine);
                    currentLine = "";
                    continue;
                }
                const currentLineWidth = ctx.measureText(currentLine + " " + word).width;
                if (currentLineWidth < props.imageWidth - (props.padding[1] * 2)) {
                    if(!currentLine.length){
                        currentLine = word;
                    } else {
                        currentLine += " " + word;
                    }
                } else {
                    if(currentLineWidth > props.imageWidth - (props.padding[1] * 2)){
                        if (ctx.measureText(word).width > props.imageWidth - (props.padding[1] * 2)){
                            let tempWord = "";
                            for(const letter of word){
                                tempWord += letter;
                                if(ctx.measureText(currentLine + " " + tempWord).width > props.imageWidth - (props.padding[1] * 2)){
                                    if(!currentLine.length){
                                        lines.push(tempWord.slice(0, -1));
                                    } else {
                                        lines.push(currentLine + " " + tempWord.slice(0, -1));
                                    }
                                    tempWord = tempWord.replace(tempWord.slice(0, -1), "");
                                    currentLine = "";
                                }
                            }
                            currentLine = tempWord;
                            continue;
                        }
                    }
                    if(currentLine) lines.push(currentLine);
                    currentLine = word;
                }
            }
            lines.push(currentLine);
            setPrevTextLines(lines);
            return lines;
        }

        // Verify that we actually need to update the textLines based on props changes
        function textLinesShouldUpdate(){
            if(prevProps?.imageHeight !== props.imageHeight) return false;
            if(prevProps?.textColor !== props.textColor) return false;
            return true;
        }

        setPrevProps(props);
        // eslint-disable-next-line
    }, [props])

    return(
        <div id="canvasContainerWrapper">
            <div id="displayedCanvasContainer">
                <canvas 
                    ref={displayedCanvasRef}
                    width={props.imageWidth}
                    height={props.imageHeight}
                    style={{display: canvasDisplay, objectFit: "contain", zIndex: 2}}
                />
                <img 
                    className="placeholderImage"
                    ref={displayedImageRef}
                    alt="preview"
                    src={props.displayedSrc ?? props.imageSrc}
                    style={{display: placeholderDisplay,  objectFit: "contain", zIndex: 2}}
                />
            </div>
            <div id="hiddenCanvasContainer">
                <canvas 
                    id="imageCanvas"
                    ref={canvasRef}
                    width={props.imageWidth}
                    height={props.imageHeight}
                    style={{display: canvasDisplay, objectFit: "contain"}}
                />
                <img 
                    className="placeholderImage"
                    ref={imageRef}
                    alt="preview"
                    src={props.imageSrc}
                    style={{display: placeholderDisplay,  objectFit: "contain"}}
                />
            </div>
        </div>
    )
}